.p-scrolltop {
  & .p-scrolltop-icon {
    font-size: 1.5rem;
    color: #fe8000 !important;
  }
  &.p-link {
    &:focus {
      box-shadow: none !important;
    }
  }
}
