@import "/src/scss/components/accordion";
@import "/src/scss/components/menu";
@import "/src/scss/components/sidebar";
@import "/src/scss/components/scrool-top";
@import "/src/scss/components/input";
@import "/src/scss/components/header";
@import "/src/scss/components/mini-header";
@import "/src/scss/components/button";
@import "/src/scss/components/fale-conosco-dialog";

.p-component {
  font-family: "Poppins", sans-serif !important;
  font-size: 13px !important;
}
