@import "./scss/variables";
@import "./scss/components";
@import "./scss/theme";
@import "./scss/responsivity";
@import "material-icons/iconfont/material-icons.css";
agm-map {
  height: 400px;
}
html,
body {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-family: "Quicksand", sans-serif !important;
  overflow-x: hidden;

  & .titulo {
    color: $dark-minus-2;

    & span {
      @include h4-bold;
    }
  }
  & .subtitulo {
    color: $dark-minus-2;
    @include p-semibold;
  }
  & .texto {
    font-weight: 200;
    text-align: justify;
    padding: 0 45px;
  }
  & .container {
    padding: 0 150px;
  }
  & .p-component {
    font-family: "Quicksand", sans-serif !important;
    font-weight: 600 !important;
  }
  & .link-texto {
    color: $primary;
    &:visited {
      color: $primary;
    }
  }
  & .pagina-detalhes {
    text-align: center;
    & .sub-header {
      min-height: 160px;
      background: $primary;
      display: flex;
      justify-content: flex-end;
      & .sub-header-titulo {
        margin-top: auto;
        margin-bottom: 20px;
        color: $light;
        @include h4-bold;
      }
    }
    & .sub-header-segmento {
      min-height: 160px;
      background: $dark-minus-1;
      display: flex;
      & .sub-header-titulo {
        margin-top: auto;
        margin-bottom: 20px;
        color: $light;
        text-align: left;
        @include h3-bold;
      }
      & .sub-header-subtitulo {
        margin-top: auto;
        margin-bottom: 20px;
        color: $light;
        text-align: left;
        @include h5;
      }
    }
    & .sub-header-solucao {
      min-height: 160px;
      display: flex;
      & .sub-header-titulo {
        text-align: left;
        @include h6-bold;
      }
      & .sub-header-subtitulo {
        text-align: left;
        @include h4-bold;
      }
      & .sub-header-texto {
        text-align: left;
        @include p-semibold;
      }
    }
  }
  & .animacao {
    position: relative;
    animation-name: animateBottomToTop;
    animation-duration: 1s;
    visibility: visible;
  }
}

@keyframes animateBottomToTop {
  from {
    bottom: -50px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
