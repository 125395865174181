body {
  & .container {
    @media (max-width: 1350px) {
      padding: 0 90px !important;
    }
    @media (max-width: 1000px) {
      padding: 0 110px !important;
    }
    @media (max-width: 600px) {
      padding: 0 24px !important;
    }
  }
  & .mini-header {
    @media (max-width: 500px) {
      display: none !important;
    }
  }
  & .header {
    @media (max-width: 500px) {
      top: 0 !important;
    }
    & .menu {
      display: block;
      @media (max-width: 950px) {
        & .menu-item {
          display: none !important;
        }
        & .button-parcerias {
          display: none !important;
        }
        & .button-sidebar {
          display: block !important;
        }
      }
    }
  }
  & .sub-header {
    @media (max-width: 500px) {
      justify-content: center !important;
    }
  }
  & .footer {
    & .links {
      & .grid {
        & .logo {
          & img {
            @media (max-width: 1000px) {
              width: 200px !important;
            }
            @media (max-width: 500px) {
              width: 150px !important;
            }
          }
        }
      }
    }
  }
  & .cookies {
    @media (max-width: 500px) {
      height: 20% !important;
      flex-direction: column !important;
      text-align: justify !important;
    }
  }
  & .home-page {
    & .servicos {
      & .lista-servicos {
        & .servico {
          & .servico-imagem {
            & img {
              @media (max-width: 500px) {
                width: 60% !important;
              }
            }
          }
        }
      }
    }
    & .sobre-nos {
      & .sobre-nos-conteudo {
        & .sobre-nos-imagem {
          & img {
            @media (max-width: 500px) {
              width: 60% !important;
            }
          }
        }
      }
    }
  }
  & .parcerias-page {
    & .descricao-parceria {
      & .lista-descricao-parceria {
        & .descricao {
          & .descricao-imagem {
            & img {
              @media (max-width: 500px) {
                width: 300px !important;
              }
            }
          }
        }
      }
    }
    & .seja-parceiro {
      @media (max-width: 500px) {
        flex-direction: column !important;
      }
    }
  }
}
