.p-accordion {
  & .p-accordion-header {
    &:not(.p-disabled) {
      & .p-accordion-header-link {
        &:focus {
          border-radius: 10px !important;
        }
      }
      &.p-highlight {
        & .p-accordion-header-link {
          border-radius: 10px !important;
        }
      }
    }
    &:not(.p-highlight) {
      &:not(.p-disabled) {
        &:hover {
          & .p-accordion-header-link {
            border-radius: 10px !important;
          }
        }
      }
    }
    & .p-accordion-header-link {
      height: 70px !important;
    }
  }
  & p-accordiontab {
    & .p-accordion-tab {
      padding: 10px !important;
    }
    & .p-accordion-header {
      & .p-accordion-header-link {
        border-radius: 10px !important;
      }
    }
    & .p-accordion-content {
      border-radius: 10px !important;
      margin-top: 15px !important;
    }
  }
  & .p-accordion-header-text {
    font-weight: 700 !important;
  }
}
