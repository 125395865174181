.p-inputtext {
  &:enabled {
    &:focus {
      box-shadow: 0 0 0 0.2rem lighten($color: #fe8000, $amount: 10) !important;
      border-color: #fe8000 !important;
    }
    &:hover {
      border-color: #fe8000 !important;
    }
  }
}
