@import '../variables/colors';
@mixin h1 {
  font-size: 82px;
  font-family: "Quicksand";
  font-weight: normal;
  font-style: normal;
  line-height: 100%;
  text-decoration: none;
}
@mixin h2 {
  font-size: 61px;
  font-family: "Quicksand";
  font-weight: normal;
  font-style: normal;
  line-height: 100%;
  text-decoration: none;
}
@mixin h2-semibold {
  font-size: 61px;
  font-family: "Quicksand";
  font-weight: 600;
  font-style: normal;
  line-height: 100%;
  text-decoration: none;
}
@mixin h2-bold {
  font-size: 61px;
  font-family: "Quicksand";
  font-weight: bold;
  font-style: normal;
  line-height: 100%;
  text-decoration: none;
}
@mixin h3 {
  font-size: 45px;
  font-family: "Quicksand";
  font-weight: normal;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
}
@mixin h3-semibold {
  font-size: 45px;
  font-family: "Quicksand";
  font-weight: 600;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
}
@mixin h3-bold {
  font-size: 45px;
  font-family: "Quicksand";
  font-weight: bold;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
}
@mixin h4 {
  font-size: 33px;
  font-family: "Quicksand";
  font-weight: normal;
  font-style: normal;
  line-height: 140%;
  text-decoration: none;
}
@mixin h4-bold {
  font-size: 31px;
  font-family: "Quicksand";
  font-weight: bold;
  font-style: normal;
  line-height: 140%;
  text-decoration: none;
}
@mixin h4-semibold {
  font-size: 31px;
  font-family: "Quicksand";
  font-weight: 600;
  font-style: normal;
  line-height: 140%;
  text-decoration: none;
}
@mixin h5 {
  font-size: 22px;
  font-family: "Quicksand";
  font-weight: normal;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
}
@mixin h5-semibold {
  font-size: 22px;
  font-family: "Quicksand";
  font-weight: 600;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
}
@mixin h5-bold {
  font-size: 22px;
  font-family: "Quicksand";
  font-weight: bold;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
}
@mixin h6 {
  font-size: 17px;
  font-family: "Quicksand";
  font-weight: normal;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
}
@mixin h6-semibold {
  font-size: 17px;
  font-family: "Quicksand";
  font-weight: 600;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
}
@mixin h6-bold {
  font-size: 17px;
  font-family: "Quicksand";
  font-weight: bold;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
}
@mixin h7 {
  font-size: 15px;
  font-family: "Quicksand";
  font-weight: normal;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
}
@mixin h7-semibold {
  font-size: 15px;
  font-family: "Quicksand";
  font-weight: 600;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
}
@mixin h7-bold {
  font-size: 15px;
  font-family: "Quicksand";
  font-weight: bold;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
}
@mixin p {
  font-size: 13px;
  font-family: "Quicksand";
  font-weight: normal;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
}
@mixin p-semibold {
  font-size: 13px;
  font-family: "Quicksand";
  font-weight: 600;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
}
@mixin p-bold {
  font-size: 13px;
  font-family: "Quicksand";
  font-weight: 800;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
}
@mixin small {
  font-size: 12px;
  font-family: "Quicksand";
  font-weight: normal;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
}
@mixin small-semibold {
  font-size: 12px;
  font-family: "Quicksand";
  font-weight: 600;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
}
@mixin small-bold {
  font-size: 12px;
  font-family: "Quicksand";
  font-weight: 800;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
}
@mixin mobile {
  font-size: 11px;
  font-family: "Quicksand";
  font-weight: normal;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
}
@mixin mobile-semibold {
  font-size: 11px;
  font-family: "Quicksand";
  font-weight: 600;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
}
@mixin mobile-bold {
  font-size: 11px;
  font-family: "Quicksand";
  font-weight: 800;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
}

.texto-primary {
  color: $primary !important;
}
.texto-light {
  color: $light !important;
}
.texto-dark-minus-1 {
  color: $dark-minus-1 !important;
}
.texto-dark-minus-2 {
  color: $dark-minus-2 !important;
}
.texto-dark-minus-3 {
  color: $dark-minus-3 !important;
}
