.light {
  background-color: $light !important;
  & .home-page {
    & .home {
      & .carousel {
        & .arrow {
          & i {
            color: $light !important;
          }
        }
      }
    }
    & .sobre-nos {
      & .infinite {
        & div {
          &.highway-barrier {
            &::before {
              background: linear-gradient(
                to right,
                $light-plus-1 0%,
                rgba(255, 255, 255, 0) 100%
              );
            }
            &::after {
              background: linear-gradient(
                to left,
                $light-plus-1 0%,
                rgba(255, 255, 255, 0) 100%
              );
            }
          }
        }
      }
    }
    & .segmentos {
      & .infinite {
        & div {
          &.highway-barrier {
            &::before {
              background: linear-gradient(
                to right,
                $light 0%,
                rgba(255, 255, 255, 0) 100%
              );
            }
            &::after {
              background: linear-gradient(
                to left,
                $light 0%,
                rgba(255, 255, 255, 0) 100%
              );
            }
          }
        }
      }
    }
  }
  & .footer {
    background-color: $dark-minus-1;
  }
  & .header-sidebar-button {
    background-color: $primary;
    color: $light;
    transition: background-color 0.3s;
    &:hover {
      background-color: darken($color: $primary, $amount: 10);
    }
  }
  & .p-sidebar {
    // background-color: $background-card !important;
    color: $primary !important;
    & .p-menu {
      background-color: transparent !important;
      & .p-menuitem-link {
        color: $primary !important;
        &:focus {
          box-shadow: inset 0 0 0 0.15rem lighten($color: $primary, $amount: 20);
        }
        &:not(.p-disabled) {
          &:hover {
            background: $primary !important;
            & .p-menuitem-text {
              color: $light !important;
            }
          }
        }
        & .p-menuitem-text {
          color: $primary !important;
        }
      }
    }
  }
  // & .g3-small-page {
  //   & .produto-header {
  //     background-color: $dark-minus-2 !important;
  //     & .navbar-top {
  //       & .navbar-top-links {
  //         & .home {
  //           & a {
  //             color: $light !important;
  //             &:hover {
  //               color: $primary !important;
  //             }
  //           }
  //         }
  //         & .seletor-tema {
  //           color: $light !important;
  //           &:hover {
  //             color: $primary !important;
  //           }
  //         }
  //       }
  //     }
  //     & .navbar-bottom {
  //       color: $light !important;
  //       & .navbar-bottom-links {
  //         color: $light !important;
  //         & span {
  //           &.ativo,
  //           &:hover {
  //             color: $primary !important;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   & .produto-header-fixo {
  //     background-color: $dark-minus-2 !important;
  //     & .navbar-top {
  //       & .navbar-top-links {
  //         & .link-sobre {
  //           color: $light !important;
  //           &.ativo,
  //           &:hover {
  //             color: $primary !important;
  //           }
  //         }
  //         & .link-ajuda {
  //           color: $light !important;
  //           &.ativo,
  //           &:hover {
  //             color: $primary !important;
  //           }
  //         }
  //         & .link-perguntas {
  //           color: $light !important;
  //           &.ativo,
  //           &:hover {
  //             color: $primary !important;
  //           }
  //         }
  //         & .seletor-tema {
  //           color: $light !important;
  //           &:hover {
  //             color: $primary !important;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   & .sobre-produto {
  //     color: $dark-minus-1 !important;
  //     & .secao {
  //       &.esquerda {
  //         background-color: $background-section !important;
  //         & .titulo-esquerda {
  //           background-color: $primary !important;
  //         }
  //       }
  //       &.direita {
  //         background-color: $background-section !important;
  //         & .titulo-direita {
  //           background-color: $primary !important;
  //         }
  //       }
  //     }
  //   }
  //   & .ajuda {
  //     & .item-ajuda {
  //       color: $dark-minus-1 !important;
  //       & .secao {
  //         &.direita {
  //           background-color: $background-section !important;
  //           & .titulo-direita {
  //             background-color: $primary !important;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   & .perguntas {
  //     & .row-search {
  //       & .input-search {
  //         background-color: $dark-minus-2;
  //       }
  //     }
  //   }
  //   & .p-panelmenu {
  //     & .p-panelmenu-header {
  //       &.p-highlight {
  //         & a {
  //           border: none !important;
  //           color: $light !important;
  //           background: $primary !important;
  //           &:hover {
  //             background: $primary !important;
  //           }
  //           &:focus {
  //             box-shadow: none !important;
  //           }
  //         }
  //       }
  //       & a {
  //         border: none !important;
  //         color: $dark-minus-1 !important;
  //         background: none !important;
  //         &:hover {
  //           background: $primary !important;
  //           color: $light !important;
  //         }
  //         &:focus {
  //           box-shadow: none !important;
  //         }
  //       }
  //     }
  //     & .p-panelmenu-content {
  //       border: none !important;
  //       background: none !important;
  //       color: $dark-minus-1 !important;
  //       & .p-menuitem {
  //         & .p-menuitem-link {
  //           &:not(.p-disabled) {
  //             &:hover {
  //               background: transparent;
  //             }
  //           }
  //         }
  //         & .p-menuitem-icon {
  //           color: $dark-minus-1 !important;
  //         }
  //         & .p-menuitem-text {
  //           color: $dark-minus-1 !important;
  //         }
  //         &:hover,
  //         &.active {
  //           & .p-menuitem-icon {
  //             font-weight: bold !important;
  //             color: $primary !important;
  //           }
  //           & .p-menuitem-text {
  //             font-weight: bold !important;
  //             color: $primary !important;
  //           }
  //         }
  //       }
  //     }
  //     & .p-menuitem {
  //       & .p-menuitem-link {
  //         &:focus {
  //           box-shadow: none !important;
  //         }
  //       }
  //     }
  //   }
  //   & .p-accordion {
  //     & .p-accordion-header {
  //       &:not(.p-disabled) {
  //         & .p-accordion-header-link {
  //           &:focus {
  //             box-shadow: none !important;
  //           }
  //         }
  //         &.p-highlight {
  //           & .p-accordion-header-link {
  //             background: $primary !important;
  //             border-color: none !important;
  //             color: $dark-minus-1 !important;
  //             &:focus {
  //               box-shadow: none !important;
  //             }
  //           }
  //         }
  //       }
  //       &:not(.p-highlight) {
  //         &:not(.p-disabled) {
  //           &:hover {
  //             & .p-accordion-header-link {
  //               background: $primary !important;
  //               border-color: none !important;
  //               color: $dark-minus-1 !important;
  //             }
  //           }
  //         }
  //       }
  //       & .p-accordion-header-link {
  //         background: $background-section !important;
  //         color: $dark-minus-1;
  //         border: none;
  //       }
  //     }
  //     & .p-accordion-content {
  //       background: $background-section !important;
  //       color: $dark-minus-1 !important;
  //       border: none;
  //     }
  //   }
  // }
  // & .p-menubar {
  //   background: $background-section;
  //   color: $primary;
  //   border: none;
  //   width: 100%;
  //   & .p-menubar-button {
  //     color: $primary;
  //     &:hover {
  //       color: $light;
  //       background: $primary;
  //     }
  //     &:focus {
  //       box-shadow: none;
  //     }
  //   }
  //   & .p-menubar-root-list {
  //     background: $background-section;
  //     & .p-menuitem {
  //       &.p-menuitem-active {
  //         & .p-menuitem-link {
  //           background: $primary;
  //           & .p-menuitem-text {
  //             color: $light;
  //           }
  //           & .p-submenu-icon {
  //             color: $light;
  //           }
  //           &:not(.p-disabled) {
  //             &:hover {
  //               background: $primary;
  //               & .p-menuitem-text {
  //                 color: $light;
  //               }
  //               & .p-submenu-icon {
  //                 color: $light;
  //               }
  //             }
  //           }
  //         }
  //       }
  //       & .p-menuitem-link {
  //         color: $primary;
  //         & .p-menuitem-text {
  //           color: $dark-minus-2;
  //         }
  //         & .p-submenu-icon {
  //           color: $dark-minus-2;
  //         }
  //         &:not(.p-disabled) {
  //           &:hover {
  //             background: $primary;
  //             color: $light;
  //             & .p-menuitem-text {
  //               color: $light;
  //             }
  //             & .p-submenu-icon {
  //               color: $light;
  //             }
  //           }
  //         }
  //         &:focus {
  //           box-shadow: none;
  //         }
  //       }
  //     }
  //   }
  //   & .p-submenu-list {
  //     background: $background-section;
  //     & .p-menuitem {
  //       & .p-menuitem-link {
  //         background: $background-section !important;
  //         & .p-menuitem-text {
  //           color: $dark-minus-2 !important;
  //         }
  //         & .p-menuitem-icon {
  //           color: $dark-minus-2 !important;
  //         }
  //         &:hover {
  //           background: $primary;
  //           & .p-menuitem-text {
  //             color: $primary !important;
  //             font-weight: 600 !important;
  //           }
  //           & .p-menuitem-icon {
  //             color: $primary !important;
  //             font-weight: 600 !important;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}
::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}
::-webkit-scrollbar-track {
  background: transparent !important;
}
::-webkit-scrollbar-thumb {
  background: lighten($color: $dark-minus-2, $amount: 10) !important;
  border-radius: 50px !important;
}
::-webkit-scrollbar-thumb:hover {
  background: $primary !important;
}
::selection {
  color: $light !important;
  background: $primary !important;
}
