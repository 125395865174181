.fale-conosco-dialog {
  & .p-dialog-header {
    background-color: $dark-minus-1 !important;
  }
  & .p-dialog-content {
    background-color: $dark-minus-1 !important;
  }
  & .p-dialog-footer {
    background-color: $dark-minus-1 !important;
  }
}
