.header {
  top: 30px;
  z-index: 2;
  height: 60px;
  background-color: $light;
  position: sticky;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);
  // @include shadow;

  & .logo {
    line-height: 50px;

    & img {
      display: inline-block;
      vertical-align: middle;
    }
  }
  & .menu {
    & .menu-list {
      height: 100%;
      margin: 0;
      display: flex;
      align-items: center;
      list-style-type: none;

      & .menu-item {
        padding: 0 10px;
        line-height: 60px;
        cursor: pointer;
        background: linear-gradient(
          transparent,
          transparent 50%,
          $dark-minus-1 50%,
          $dark-minus-1
        );
        background-size: 100% 200%;
        transition: background 0.5s;

        & a {
          @include p-bold;
          text-decoration: none;
          color: $dark-minus-1;
          transition: all 0.3s ease-in;

          & .menu-item-icon {
            font-size: 12px;
            margin-left: 5px;
            color: $primary;
          }
        }
        & .submenu {
          padding: 15px;
          margin-top: 10px;
          border-radius: 5px;
          opacity: 0;
          visibility: hidden;
          list-style-type: none;
          position: absolute;
          transition: all 0.3s ease-in;
          display: flex;
          cursor: auto;
          background-color: $dark-minus-1;
          transform: translateY(20px);
          overflow: hidden;
          @include shadow-2;

          &.solucoes {
            left: 15%;
            min-height: 300px;
            @include p;
            & .submenu-item-solucao {
              margin: 10px;
              max-width: 350px;
              display: flex;
              flex-direction: column;
              & span {
                margin-top: 10px;
                margin-bottom: auto;
                color: $light;
              }
              & button {
                @include p-semibold;
                max-width: 150px;
                padding: 5px 20px;
                border-radius: 5px;
                color: $primary;
                background-color: transparent;
                margin-left: auto;
                cursor: pointer;
                border: 1px solid $primary;
                &:hover {
                  background-color: $primary;
                  color: $light;
                  transition: all 0.3s ease-in;
                }
              }
            }
          }
          &.funcionalidades {
            left: 22%;
            min-height: 300px;
            @include p;
            & .submenu-item-funcionalidade {
              margin: 10px;
              display: flex;
              flex-direction: column;
              & h2 {
                color: $primary;
              }
              & span {
                margin-top: 10px;
                color: $light;
              }
            }
          }
          &.segmentos {
            left: 40%;
            min-height: 230px;
            @include p;
            & .submenu-item-segmento {
              margin: 10px;
              display: flex;
              flex-direction: column;
              & span {
                margin-top: 10px;
                & a {
                  color: $light;
                  @include p;
                  &:hover {
                    color: $primary;
                  }
                }
              }
            }
          }
        }

        &:hover {
          background-position: 100% 100%;
          & a {
            transition: all 0.3s ease-in;
            color: $primary;
          }
          & .submenu {
            transform: translateY(20px);
            transition: all 0.3s ease-in;
            opacity: 1;
            visibility: visible;
            margin-top: -20px;
          }
        }
      }
      & .button-sidebar{
        display: none;
        & .pi{
          font-size: 1.5rem;
        }
      }
    }
  }
}
