.primary-button {
  & button {
    height: 30px;
    border: {
      radius: 5px;
      color: $primary;
    }
    background: {
      color: $primary;
    }
    min-width: 100px;
    padding: {
      left: 32px;
      right: 32px;
    }
    @include shadow;
    &:enabled {
      &:hover {
        border: {
          radius: 5px;
          color: $primary-plus-1;
        }
        background: {
          color: $primary-plus-1;
        }
      }
      &:focus {
        @include shadow;
      }
    }
    & .p-button-label {
      @include p-semibold;
    }
  }
}

.primary-button-outline {
  & button {
    height: 40px;
    border: {
      radius: 5px;
      color: $light;
    }
    background: {
      color: transparent;
    }
    min-width: 100px;
    padding: {
      left: 32px;
      right: 32px;
    }
    &:enabled {
      &:hover,
      &:active {
        color: $primary;
        border: {
          radius: 5px;
          color: $light;
        }
        background: {
          color: $light;
        }
      }
      &:focus {
        @include shadow;
      }

    }
    & .p-button-label {
      @include p-semibold;
    }
  }
}

.on-light-button-outline {
  & button {
    height: 40px;
    color: $primary;
    min-width: 100px;
    border: {
      radius: 5px;
      width: 1.5px;
      color: $primary
    }
    background: {
      color: transparent;
    }
    padding: {
      left: 32px;
      right: 32px;
    }
    &:enabled {
      &:hover,
      &:active {
        color: $light;
        border: {
          radius: 5px;
          color: $primary;
        }
        background: {
          color: $primary;
        }
      }
      &:focus {
        @include shadow;
      }

    }
    & .p-button-label {
      @include p-bold;
    }
  }
}

.secondary-button {
  & button {
    @include p-semibold;
    height: 40px;
    color: $primary;
    border: {
      radius: 5px;
      color: $light;
    }
    background: {
      color: $light;
    }
    min-width: 100px;
    padding: {
      left: 32px;
      right: 32px;
    }
    &:enabled {
      &:hover {
        color: $primary;
        border: {
          radius: 5px;
          color: $light-plus-1;
        }
        background: {
          color: $light-plus-1;
        }
      }
      &:focus {
        @include shadow;
      }
    }
    & .p-button-label {
      @include p-semibold;
    }
  }
}

.small-button {
  & button {
    min-width: 120px;
    height: 33px;
  }
}

.icon-button {
  &.p-button {
    background-color: transparent;
    &.p-button-text {
      color: $dark-minus-2;
    }
    &:enabled {
      &:hover {
        background-color: $light-plus-1;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
}
