$primary-minus-1: #f28c24;
$primary: #fe8000;
$primary-plus-1: #d96d00;
$light: #ffffff;
$light-plus-1: #e0e0e0;
$light-plus-2: #b2b2b2;
$dark-minus-3: #737373;
$dark-minus-2: #4d4d4d;
$dark-minus-1: #262626;
$dark: #000000;
$success: #219653;
$danger: #f93030;
$warning: #eab71d;
$info: #2f80ed;
